import React, { useEffect, useRef, useState } from "react";
import Field from "resources/home/field.png";
import Img1 from "resources/home/transition-image-1.png";
import Img2 from "resources/home/transition-image-2.png";
import Img3 from "resources/home/transition-image-3.png";
import Img4 from "resources/home/transition-image-4.png";
import Pin from "resources/common/icons/pin.svg";
import Phone from "resources/common/icons/phone.svg";
import Email from "resources/common/icons/email.svg";
import Farmer from "resources/common/farmer.jpg";

const ImageTransition = ({ image }) => {
  const [currentImage, setCurrentImage] = useState(0);
  let transitionImages;
  if (image === "transition") transitionImages = [Img1, Img2, Img3, Img4];
  else if (image === "farmer") transitionImages = [Farmer];
  const intervalRef = useRef(null);

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % transitionImages.length);
  };

  useEffect(() => {
    intervalRef.current = setInterval(nextImage, 4000);
    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, [currentImage]);

  return (
    <React.Fragment>
      {transitionImages.map((img, index) => (
        <div
          className="absolute w-full h-[650px] flex justify-end items-end pointer-events-none"
          key={index}
        >
          <img
            className={`h-2/3 w-1/3 z-10 rounded-tl-[60%] object-cover ${
              index === currentImage ? "opacity-100" : "opacity-0"
            } transition-opacity duration-1000`}
            alt="scenery"
            src={img}
          />
          <div className="absolute z-0 h-2/3 w-1/3 rounded-tl-[60%] bg-gray-500" />
        </div>
      ))}
    </React.Fragment>
  );
};

const Button = ({ text }) => {
  return (
    <div className="w-32 h-10 border-[1px] border-white rounded-full text-lg flex items-center justify-center cursor-pointer hover:bg-white hover:text-lime-700 transition-all duration-200">
      {text}
    </div>
  );
};

const Path = ({ pageName }) => {
  return (
    <div className="flex">
      <div className="text-sm mr-2 cursor-pointer hover:underline">Home</div>
      <div className="text-lg mr-2 flex items-center">
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 9.10606L0.89394 10L5.89394 5L0.89394 0L0 0.893939L4.10606 5L0 9.10606Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="text-sm cursor-pointer">{pageName}</div>
    </div>
  );
};

const ContactBox = () => {
  return (
    <div className="absolute w-full h-[650px] flex justify-end items-end">
      <div className="p-4 mr-32">
        <div className="w-[400px] px-10 py-7 shadow-2xl bg-white rounded-lg">
          <div className="text-[#0D253C] opacity-80 font-semibold text-lg mb-4">
            Office Address
          </div>
          <div className="flex items-start mb-8">
            <img src={Pin} alt="" className="mr-2 mt-1" />
            <div className="w-5/6 text-[#000B33]">
              123 Town Name St. StreetName, City code 1244332
            </div>
          </div>
          <div className="text-[#0D253C] opacity-80 font-semibold text-lg mb-4">
            Contact Info
          </div>
          <div className="flex items-start mb-6">
            <img src={Phone} alt="" className="mr-2 mt-1" />
            <div className="w-5/6 text-[#000B33]">+123 467 5628 99</div>
          </div>{" "}
          <div className="flex items-start">
            <img src={Email} alt="" className="mr-2 mt-1" />
            <div className="w-5/6 text-[#000B33]">landingdata@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Overview = ({
  rightSection = "image",
  rightSectionImage = "transition",
  title,
  desc,
  buttonType = "button",
  pageName = "",
}) => {
  return (
    <div className="w-full h-[650px]">
      <div className="absolute h-[650px] w-[89%] pointer-events-none">
        <img
          className="w-full h-full rounded-br-full"
          src={Field}
          alt="cover"
        />
        <div className="absolute w-full h-full rounded-br-full inset-0 bg-gradient-to-r from-lime-600 to-gray-800 opacity-80" />
      </div>
      {rightSection === "image" && (
        <ImageTransition image={rightSectionImage} />
      )}
      {rightSection === "contact" && <ContactBox />}
      <div className="absolute h-[650px] max-w-[600px] flex flex-col justify-center text-white ml-20">
        <div className="text-[44px] font-semibold max-w-[450px] leading-tight mb-4">
          {title}
        </div>
        <div className="text-[22px] mb-10">{desc}</div>
        {buttonType === "button" && <Button text={"Get Started"} />}
        {buttonType === "path" && <Path pageName={pageName} />}
      </div>
    </div>
  );
};

export default Overview;
