import GeoContext from "contexts/GeoContext";
import { useContext } from "react";

const AreaCheck = () => {
  const { totalArea } = useContext(GeoContext);
  return (
    <div
      className={`text-center mt-4 text-white text-xl ${totalArea ? "opacity-100" : "opacity-0"} transition-opacity duration-500`}
    >
      <span className="font-medium">Selected Area:</span>{" "}
      <span className={`${totalArea > 500000 ? "text-orange-400" : ""}`}>
        {totalArea} m²{" "}
      </span>
      / 500000 m²
    </div>
  );
};

export default AreaCheck;
