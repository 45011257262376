import Divider from "components/common/Divider";
import Member1 from "resources/about/team/member 1.png";
import Member2 from "resources/about/team/member 2.png";
import Member3 from "resources/about/team/member 3.png";
import Member4 from "resources/about/team/member 4.png";

const MemberCard = ({ profile, name, role }) => {
  return (
    <div className="py-6 mr-5 px-4 mb-5 w-64 flex flex-col items-center rounded-lg shadow-card">
      <img src={profile} alt="" className="w-60 rounded-md" />
      <div className="ml-4 mt-4 text-[#000B33] w-full">
        <div className="font-medium text-xl">{name}</div>
        <div className="text-sm opacity-70">{role}</div>
      </div>
      <div></div>
    </div>
  );
};

const Team = () => {
  return (
    <div className="mb-24">
      <div className="text-center font-medium text-[42px] text-[#262B35]">
        Our qualified team
      </div>
      <Divider />
      <div className="flex justify-center">
        <div className="text-center text-[#7E7E7E] mt-6 max-w-[450px]">
          Fully layered dolor sit amet, consectetur Facere, nobis, id expedita
          dolores officiis laboriosam.
        </div>
      </div>
      <div className="flex flex-wrap justify-center px-32 my-12">
        <MemberCard profile={Member1} name="Jake Kay" role="Co -Founder" />
        <MemberCard
          profile={Member2}
          name="William Morres"
          role="Project Manager"
        />
        <MemberCard profile={Member3} name="Ray Hayj" role="Web Designer" />
        <MemberCard profile={Member4} name="Edward Ethens" role="HR Manager" />
      </div>
    </div>
  );
};

export default Team;
