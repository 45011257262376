import Login from "components/account/Login";
import OrderHistory from "components/account/OrderHistory";
import Background from "components/common/Background";

const Account = () => {
  return (
    <Background>
      <Login />
      <OrderHistory />
    </Background>
  );
};

export default Account;
