import Overview from "components/common/Overview";
import Analytics from "components/home/Analytics";
import Benefits from "components/home/Benefits";
import Distinctions from "components/home/Distinctions";
import Features from "components/home/Features";
import Results from "components/home/Results";
import Reviews from "components/home/Reviews";

const Home = () => {
  return (
    <div>
      <Overview
        title={"Revolutionize Your Land Management"}
        desc={"Data-Driven Insights for Golf Courses & Landowners"}
      />
      <Analytics />
      <Features />
      <Benefits />
      <Distinctions />
      <Results />
      <Reviews />
    </div>
  );
};

export default Home;
