import Divider from "components/common/Divider";
import Overview from "components/common/Overview";
import PackageCollection from "components/packages/PackagesCollection";

const Packages = () => {
  return (
    <div>
      <Overview
        title="Packages"
        desc="Discover the perfect package according to your requirements"
        buttonType="path"
        pageName="packages"
        rightSectionImage="farmer"
      />
      <div className="text-center font-medium text-[42px] text-[#262B35] mt-10">
        Our Packages
      </div>
      <Divider className="w-1/2" />
      <div className="flex justify-center">
        <div className="text-center text-[#7E7E7E] mt-6 max-w-[450px] mb-10">
          Choose from one of our best packages
        </div>
      </div>

      <PackageCollection />
    </div>
  );
};

export default Packages;
