import Divider from "components/common/Divider";
import ReactCompareImage from "react-compare-image";
import LeftImage from "resources/home/match-left.png";
import RightImage from "resources/home/match-right.png";

const Results = () => {
  return (
    <div>
      <div className="font-medium text-[40px] text-center mt-20 text-[#262B35]">
        Some of our results
      </div>
      <Divider className="mb-20" />
      <div className="m-20">
        <ReactCompareImage
          leftImage={LeftImage}
          rightImage={RightImage}
          aspectRatio={"wider"}
          leftImageCss={{
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
          }}
          rightImageCss={{
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        />
      </div>
    </div>
  );
};

export default Results;
