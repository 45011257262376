import GeoContext from "contexts/GeoContext";
import { useContext, useEffect, useRef } from "react";
import Globe from "react-globe.gl";
import EarthDay from "resources/analyze/earth-day.jpg";

const Earth = () => {
  const globeRef = useRef(null);
  const locationRef = useRef(null);

  const { setLocation } = useContext(GeoContext);

  const handleZoom = async () => {
    if (!globeRef.current) return;

    const prevAltitude = locationRef.current.altitude;
    const altitude = globeRef.current.pointOfView().altitude;

    if (Math.abs(prevAltitude - altitude) >= 0.04) {
      globeRef.current.pointOfView({
        ...globeRef.current.pointOfView(),
        altitude: 2,
      });
      const data = globeRef.current.pointOfView();
      // setLocation({ latitude: data.lat, longitude: data.lng });
    } else {
      globeRef.current.pointOfView({
        ...globeRef.current.pointOfView(),
      });
    }

    locationRef.current = {
      ...globeRef.current.pointOfView(),
      altitude: locationRef.current.altitude,
    };
  };

  const handleCrossClick = () => {
    if (!globeRef.current) return;
    const data = globeRef.current.pointOfView();
    setLocation({ latitude: data.lat, longitude: data.lng, zoom: 6 });
  };

  useEffect(() => {
    if (!globeRef.current) return;
    globeRef.current.controls().enableZoom = false;

    const initalPov = { ...globeRef.current.pointOfView(), altitude: 2 };
    globeRef.current.pointOfView(initalPov);
    locationRef.current = initalPov;
  }, [globeRef]);

  return (
    <div style={{ position: "relative" }}>
      <Globe
        backgroundColor="rgba(0,0,0,0)"
        width={600}
        height={600}
        globeImageUrl={EarthDay}
        atmosphereColor={"#000000"}
        atmosphereAltitude={0.12}
        onZoom={handleZoom}
        ref={globeRef}
      />
      <div className="absolute top-0 w-full h-full flex justify-center items-center z-50 pointer-events-none select-none">
        <div
          className="text-gray-200 text-2xl pointer-events-auto hover:text-red-500 cursor-pointer"
          onClick={handleCrossClick}
        >
          🕂
        </div>
      </div>
    </div>
  );
};

export default Earth;
