import Home from "pages/Home";
import About from "pages/About";
import Contact from "pages/Contact";
import Analyze from "pages/Analyze";
import Packages from "pages/Packages";
import mapPointer from "resources/analyze/square.svg";
import mapMidPointer from "resources/analyze/map-mid-pointer.svg";
import mapPointerSecondary from "resources/analyze/map-pointer-secondary.svg";
import L from "leaflet";

export const PAGES = [
  {
    title: "home",
    path: "/",
    element: <Home />,
  },
  {
    title: "analyze your land",
    path: "/analyze",
    element: <Analyze />,
  },
  {
    title: "packages",
    path: "/packages",
    element: <Packages />,
  },
  {
    title: "about",
    path: "/about",
    element: <About />,
  },
  {
    title: "contact us",
    path: "/contact",
    element: <Contact />,
  },
];

export const package1Benefits = [
  "Crop Growth Monitoring (EVI)",
  "Current Weather Information",
  "Limited Historical Data",
  "Monthly updates",
  "Community support",
];

export const package1NonBenefits = [
  "AI Reports",
  "Updates every 5 days",
  "Email support",
];

export const package2Benefits = [
  "Crop Growth Monitoring (EVI)",
  "Soil Moisture Monitoring (NDMI)",
  "Surface Water Detection (NDWI)",
  "Nitrogen Content Estimation (REIP)",
  "Pests and Diseases Detection (PSRI)",
  "Burned Land Assessment (NBR)",
  "Current Weather Information",
  "Reports",
  "Extended Historical Data",
  "Cloud Masking",
  "AI Reports",
  "Updates every 5 days",
  "Email support",
];

export const package3Benefits = [
  "Crop Growth Monitoring (EVI)",
  "Soil Moisture Monitoring (NDMI)",
  "Surface Water Detection (NDWI)",
  "Nitrogen Content Estimation (REIP)",
  "Pests and Diseases Detection (PSRI)",
  "Burned Land Assessment (NBR)",
  "Current Weather Information",
  "Reports",
  "Full Historical Data",
  "Cloud Masking",
  "AI Reports",
  "Contamination Detection",
  "Notes about Surrounding Area",
  "Customizable Analysis",
  "Machine Learning Applications",
  "Updates every 5 days",
  "Priority support with dedicated account manager.",
];

export const mapIcon = new L.Icon({
  iconUrl: mapPointer,
  iconSize: [16, 16],
  // iconAnchor: [8, 16],
  // popupAnchor: [0, -16],
});

export const mapMidIcon = new L.Icon({
  iconUrl: mapMidPointer,
  iconSize: [16, 16],
  // iconAnchor: [8, 16],
  // popupAnchor: [0, -16],
});

export const mapIconSecondary = new L.Icon({
  iconUrl: mapPointerSecondary,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});
