import axios from "apis/weather/axios";

export const getCurrentWeather = (lat, lon) => {
  return axios
    .get("http://api.weatherapi.com/v1/current.json", {
      params: {
        q: `${lat},${lon}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching current weather data:", error);
      return Promise.reject(error); // Reject the promise to propagate the error
    });
};
