import Divider from "components/common/Divider";
import NDVI from "resources/about/icons/ndvi.svg";
import Soil from "resources/about/icons/soil.svg";
import Nitrogen from "resources/about/icons/nitrogen.svg";
import Pest from "resources/about/icons/pest.svg";

const Card = ({ icon, title, text }) => {
  return (
    <div className="w-[450px] flex flex-col items-center py-10 shadow-card mb-14">
      <img src={icon} alt="" />
      <div className=" font-medium text-xl text-[#000B33] mt-8">{title}</div>
      <div className="text-[#0D253C] opacity-70 max-w-[250px] mt-4">{text}</div>
    </div>
  );
};

const Method = () => {
  return (
    <div className="px-10 md:px-40">
      <div className="text-center font-medium text-[42px] text-[#262B35]">
        How We Do It
      </div>
      <Divider />
      <div className="flex justify-center">
        <div className="text-center text-[#7E7E7E] mt-6 max-w-[450px]">
          An enim nullam tempor sapien gravida donec enim ipsum porta justo
          integer at odio velna gna at pretium
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-items-center mt-10">
        <Card
          icon={NDVI}
          title="NDVI"
          text="Monitor plant health, biomass, and stress levels to optimize irrigation, fertilization, and pest management."
        />
        <Card
          icon={Soil}
          title="Soil Moisture"
          text="Track soil hydration levels for efficient water management, preventing drought stress and optimizing crop yields."
        />
        <Card
          icon={Nitrogen}
          title="Nitrogen Content"
          text="Assess nitrogen levels to optimize fertilization practices, improve crop quality, and reduce environmental impact."
        />
        <Card
          icon={Pest}
          title="Potential Pest and Disease"
          text="Detect early signs of threats to protect your crops and prevent significant losses."
        />
      </div>
    </div>
  );
};

export default Method;
