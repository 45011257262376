import React, { useRef, useState } from "react";
import Slider from "react-slick";
import Profile1 from "resources/home/reviews/profile1.png";
import Profile2 from "resources/home/reviews/profile2.png";
import Profile3 from "resources/home/reviews/profile3.png";
import Profile4 from "resources/home/reviews/profile4.png";
import Quotes from "resources/home/icons/quotes.svg";
import LeftArrow from "resources/home/icons/arrow-left.jsx";
import RightArrow from "resources/home/icons/arrow-right.jsx";

const reviews = [
  {
    profile: Profile1,
    name: "Jow Kim",
    time: "2 months ago",
    text: "consetetur sadipscing elltr, sed dlam nonumy elrmod tempor invidunt ut labore et dolore magna allquyam erat, sed dlam voluptua ares nyan.",
  },
  {
    profile: Profile2,
    name: "Jow Kim",
    time: "2 months ago",
    text: "consetetur sadipscing elltr, sed dlam nonumy elrmod tempor invidunt ut labore et dolore magna allquyam erat, sed dlam voluptua ares nyan.",
  },
  {
    profile: Profile3,
    name: "Jow Kim",
    time: "2 months ago",
    text: "consetetur sadipscing elltr, sed dlam nonumy elrmod tempor invidunt ut labore et dolore magna allquyam erat, sed dlam voluptua ares nyan.",
  },
  {
    profile: Profile4,
    name: "Jow Kim",
    time: "2 months ago",
    text: "consetetur sadipscing elltr, sed dlam nonumy elrmod tempor invidunt ut labore et dolore magna allquyam erat, sed dlam voluptua ares nyan.",
  },
];

const ReviewCard = ({ review }) => {
  return (
    <div className="w-96 p-10 rounded-lg bg-white">
      <div className="flex justify-between">
        <div className="flex">
          <img src={review.profile} alt="" className="rounded-full mr-4 w-11" />
          <div className="flex flex-col justify-center">
            <div className=" text-[#262729] font-medium text-sm mb-1">
              {review.name}
            </div>
            <div className="text-xs text-[#262729] opacity-70">
              {review.time}
            </div>
          </div>
        </div>
        <img src={Quotes} alt="" />
      </div>
      <div className="text-sm text-[#000B33] mt-4 opacity-70 leading-tight">
        {review.text}
      </div>
    </div>
  );
};

const Reviews = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    slidesToShow: 3.05,
    infinite: false,
    duration: 500,
    beforeChange: (_, newIndex) => setCurrentSlide(newIndex),
    nextArrow: <React.Fragment />,
    prevArrow: <React.Fragment />,
  };

  const totalSlides = Math.max(reviews.length - sliderSettings.slidesToShow, 0);

  const sliderRef = useRef(null);

  const slideNext = () => {
    if (!sliderRef.current || currentSlide === totalSlides) return;
    sliderRef.current.slickNext();
  };

  const slidePrev = () => {
    if (!sliderRef.current || currentSlide === 0) return;
    sliderRef.current.slickPrev();
  };

  return (
    <div className="pt-10 bg-[#F3F3F3]">
      <div className="mx-20 mt-20 max-w-[1220px]">
        <div className="flex justify-between">
          <div className="text-[42px] font-medium mt-4">
            What people say about us?
          </div>
          <div className="flex">
            <div
              className={`select-none w-11 h-11 rounded-full cursor-pointer flex justify-center items-center mr-3 ${
                currentSlide === 0
                  ? "bg-white text-[#3C5E23]"
                  : "bg-[#3C5E23] text-white"
              } transition-colors duration-500`}
              onClick={slidePrev}
            >
              <LeftArrow className="text-inherit" />
            </div>
            <div
              className={`select-none w-11 h-11 rounded-full cursor-pointer flex justify-center items-center ${
                currentSlide === totalSlides
                  ? "bg-white text-[#3C5E23]"
                  : "bg-[#3C5E23] text-white"
              } transition-colors duration-500`}
              onClick={slideNext}
            >
              <RightArrow className="text-inherit" />
            </div>
          </div>
        </div>
      </div>
      <div className="ml-20 py-10 max-w-[1220px] p-2 ">
        <Slider {...sliderSettings} ref={sliderRef}>
          {reviews.map((review, index) => (
            <ReviewCard review={review} key={index} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Reviews;
