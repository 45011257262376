import Method from "components/about/Method";
import Mission from "components/about/Mission";
import Commitment from "components/about/Commitment";
import Team from "components/about/Team";
import Overview from "components/common/Overview";

const About = () => {
  return (
    <div>
      <Overview
        title={"About"}
        desc={"We are transforming how you manage your land"}
        buttonType="path"
        pageName="About us"
        rightSection="none"
      />
      <Mission />
      <Method />
      <Commitment />
      <Team />
    </div>
  );
};

export default About;
