import Google from "resources/common/icons/google.svg";

const GoogleButton = ({ text, onClick, hidden = false }) => {
  return (
    <a
      href={process.env.REACT_APP_BASE_URL + "/google_login"}
      className={`px-4 py-2 my-4 text-center font-medium rounded-lg select-none cursor-pointer bg-gray-100 text-gray-700 flex items-center transition-all duration-300 overflow-hidden ${
        hidden
          ? "max-h-0 opacity-0 pointer-events-none"
          : "max-h-screen opacity-100 pointer-events-auto"
      }`}
    >
      <img src={Google} alt="" className="w-8 mr-2" />
      {text}
    </a>
  );
};

export default GoogleButton;
