import axios from "apis/backend/axios";

export const login = async (email, password) => {
  const body = { email, password };
  try {
    const response = await axios.post("/login", body);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const signup = async (name, email, password) => {
  const body = { name, email, password, "re-password": password };
  try {
    const response = await axios.post("/signup", body);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email) => {
  const body = { email };
  try {
    const response = await axios.post("/forgot_password", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyResetpassword = async (email, token) => {
  const body = { email, token };
  try {
    const response = await axios.post("/verify_reset_password_token", body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (email, password, resetToken) => {
  const body = { email, password, re_password: password };

  try {
    const response = await axios.post("/set_new_password", body, {
      headers: {
        Authorization: resetToken,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const googleLogin = async () => {
  try {
    const response = await axios.post("/google_login");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const requestImages = async (points) => {
  const body = { geo_locations: [points] };

  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post("/new_search", body, { headers });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getAllOrders = async () => {
  try {
    const response = await axios.put("/get_user_searches");
    return response.data.data || [];
  } catch (error) {
    throw error;
  }
};

export const getCoords = async (userId, searchId) => {
  if (!userId || !searchId) return null;
  const link = encodeURI(
    `/user_searches/${userId}/${searchId}/coordinates.geojson`
  );
  try {
    const response = await axios.get(link);
    return [
      [29.68670380840619, 72.30962535146595],
      [29.68662924327105, 72.31215803324018],
      [29.684876946669867, 72.31177169195257],
      [29.684867625861582, 72.3096682782757],
    ];

    return response.features.geometry.coordinates[0];
  } catch (error) {
    throw error;
  }
};
