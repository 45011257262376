import { getAllOrders, getCoords } from "apis/backend";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import {
  findMessage,
  getAverageCoord,
  getGoogleImageryUrl,
  getImageLinks,
  getMainImageLink,
  isLoggedIn,
} from "utils";
import { BarLoader, MoonLoader } from "react-spinners";
import { MapContainer, Marker, Polygon, TileLayer } from "react-leaflet";
import { mapIconSecondary } from "constants";
import Zoom from "react-medium-image-zoom";

import Feature1 from "resources/home/icons/feature1.svg";
import Feature2 from "resources/home/icons/feature2.svg";
import Feature3 from "resources/home/icons/feature3.svg";
import Feature4 from "resources/home/icons/feature4.svg";
import Feature5 from "resources/home/icons/feature5.svg";
import scrollIntoView from "scroll-into-view-if-needed";
import { reverseGeocode } from "apis/positionstack";

const OrderRow = ({ order, index, onView, viewIndex }) => {
  const [location, setLocation] = useState(null);

  function formatAddress(geocodeData) {
    console.log(geocodeData);
    // Extracting relevant fields
    const country = geocodeData.country;
    const region = geocodeData.region;
    const county = geocodeData.county;
    const locality = geocodeData.locality || "";
    const name = geocodeData.name || "";

    // Building the address string
    const addressParts = [];
    if (locality) addressParts.push(locality);
    if (name) addressParts.push(name);
    if (county) addressParts.push(county);
    if (region) addressParts.push(region);
    if (country) addressParts.push(country);

    // Joining the parts with commas and returning the result
    return addressParts.join(", ");
  }

  useEffect(() => {
    const getLocation = async () => {
      const { lat, lon } = getAverageCoord(
        order.geo_locations[0].map((coord) => [
          parseFloat(coord[0]),
          parseFloat(coord[1]),
        ])
      );
      setLocation(formatAddress((await reverseGeocode(lat, lon)).data[0]));
    };

    getLocation();
  }, [order]);

  console.log(location);
  return (
    <tr
      className={`text-white font-light ${index === viewIndex ? "bg-green-500" : index % 2 === 0 ? "bg-gray-400" : "bg-gray-600"} bg-opacity-20`}
    >
      {/* <td className="py-3 pl-4 w-2/5">{order.search_id}</td> */}
      <td className="px-2">
        <div className="flex flex-col w-60 items-center">
          <Zoom>
            <img
              src={getGoogleImageryUrl(
                order.geo_locations[0].map((coord) => [
                  parseFloat(coord[0]),
                  parseFloat(coord[1]),
                ])
              )}
              alt=""
              className="w-40 h-40 mx-4 my-2 rounded-xl border-2 border-gray-600"
            />
          </Zoom>
          <div className="flex mb-4 justify-center w-full ">
            <img
              src={Feature1}
              alt=""
              className="w-8 h-8 p-1 mx-1 bg-white bg-opacity-50 rounded border border-green-400"
            />
            <img
              src={Feature2}
              alt=""
              className="w-8 h-8 p-1 mx-1 bg-white bg-opacity-50 rounded border border-blue-400"
            />
            <img
              src={Feature3}
              alt=""
              className="w-8 h-8 p-1 mx-1 bg-white bg-opacity-50 rounded border border-orange-400"
            />
            <img
              src={Feature4}
              alt=""
              className="w-8 h-8 p-1 mx-1 bg-white bg-opacity-50 rounded border border-cyan-400"
            />
            <img
              src={Feature5}
              alt=""
              className="w-8 h-8 p-1 mx-1 bg-white bg-opacity-50 rounded border border-red-400"
            />
          </div>
        </div>
      </td>
      <td>
        <div className="w-full h-48">
          <div className="text-md max-w-60">
            {location === null ? (
              <MoonLoader size={24} color={"white"} />
            ) : (
              location || "Unknown"
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="flex flex-col w-full h-48">
          <div class="leading-none mb-1 text-lg font-medium">
            {order.search_time
              ? (() => {
                  const date = new Date(order.search_time * 1000);

                  if (isNaN(date.getTime())) {
                    return "Invalid date";
                  }

                  return date.toLocaleDateString("en-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                })()
              : "Not found"}
          </div>
          <div className="text-sm text-gray-100">
            {order.search_time
              ? (() => {
                  const date = new Date(order.search_time * 1000);

                  if (isNaN(date.getTime())) {
                    return "Invalid date";
                  }

                  return date.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  });
                })()
              : "Not found"}
          </div>
        </div>
      </td>
      {/* <td>
        <div className=" h-48">
          <div
            className={`${order.status === "pending" ? "bg-orange-500" : order.status === "completed" ? "bg-green-500" : "bg-red-500"} bg-opacity-70 w-fit px-2 rounded-xl pt-[2px]`}
          >
            {order.status.toUpperCase()}
          </div>
        </div>
      </td> */}
      <td>
        <div className=" h-48">
          <div
            className={`w-fit select-none px-2 py-1 ${order.status ? "bg-green-500 cursor-pointer" : "bg-gray-500 bg-opacity-70"} rounded-md text-sm font-medium`}
            onClick={() => viewIndex !== index && onView()}
          >
            View Results
          </div>
        </div>
      </td>
    </tr>
  );
};

const OrderTable = ({
  orders,
  onViewImage,
  viewIndex,
  setLoader,
  setError,
  visited,
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="text-4xl w-fit text-white px-2 text-center font-semibold mt-8 border-b-2 border-gray-300 pb-2">
        Search History
      </div>
      <div className="w-[80vw] text-start border border-gray-700 rounded-t-lg overflow-hidden my-10">
        <table className="w-full">
          <thead>
            <tr className="text-white font-bold text-xl bg-gray-700 bg-opacity-60">
              <td className="py-2 pl-4">Order ID</td>
              <td>Location</td>
              <td>Order Date</td>
              {/* <td>Status</td> */}
              <td>Results</td>
            </tr>
          </thead>

          <tbody>
            {orders.map((order, index) => (
              <OrderRow
                key={index}
                order={order}
                index={index}
                onView={() =>
                  setError(null) ||
                  (!visited.current[index] && setLoader(true)) ||
                  onViewImage(index)
                }
                viewIndex={viewIndex}
              />
            ))}
          </tbody>
        </table>
        {orders.length === 0 && (
          <div className="text-gray-100 text-xl text-center my-1">
            You currently have no orders
          </div>
        )}
      </div>
    </div>
  );
};

const OrderHistory = () => {
  //order table states
  const [orders, setOrders] = useState(null);
  const [viewIndex, setViewIndex] = useState(null);
  const visited = useRef(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [points, setPoints] = useState(null);

  //map states
  const [layer, setLayer] = useState("satellite");
  const mapRef = useRef(null);
  const imageContainerRef = useRef(null);

  const flyToLocation = (lat, lon, zoom = null, animate = true) => {
    if (mapRef.current) {
      mapRef.current.flyTo([lat, lon], zoom || 13, {
        animate: false,
      });
    }
  };

  useEffect(() => {
    if (!isLoggedIn()) return;
    const getOrders = async () => {
      try {
        let data = await getAllOrders();
        if (data?.length) data = data.reverse();
        setOrders(data);
        visited.current = new Array(data.length).fill(false);
      } catch (e) {
        toast.error(findMessage(e, "Error fetching orders"));
      }
    };

    getOrders();
  }, []);

  useEffect(() => {
    // const fetchCoords = async () => {
    //   if (viewIndex === null) return;
    //   if (pointsCache.current[viewIndex]) return pointsCache.current[viewIndex];
    //   setMapLoader(true);
    //   try {
    //     const coords = await getCoords(
    //       orders[viewIndex].user_id,
    //       orders[viewIndex].search_id
    //     );
    //     setPoints(coords);
    //     pointsCache.current[viewIndex] = coords;
    //     setMapLoader(false);
    //     const { lat, lon } = getAverageCoord(coords);
    //     flyToLocation(lat, lon);
    //   } catch (e) {
    //     setMapLoader(false);
    //   }
    // };

    // fetchCoords();
    if (viewIndex === null) return;
    let coords = orders[viewIndex].geo_locations[0];
    coords = coords.map((coord) => [
      parseFloat(coord[0]),
      parseFloat(coord[1]),
    ]);
    const { lat, lon } = getAverageCoord(coords);
    setPoints(coords);
    flyToLocation(lat, lon);
    scrollIntoView(imageContainerRef.current, {
      behavior: "smooth",
      scrollMode: "always",
      block: "center",
    });
  }, [viewIndex]);

  const images = useMemo(
    () =>
      viewIndex !== null
        ? getImageLinks(orders[viewIndex].user_id, orders[viewIndex].search_id)
        : [],
    [viewIndex, orders]
  );

  //add loader here in future
  if (!orders) return;

  return (
    <div className="flex flex-col items-center w-full">
      <OrderTable
        orders={orders}
        onViewImage={setViewIndex}
        viewIndex={viewIndex}
        setLoader={setLoader}
        setError={setError}
        visited={visited}
      />
      {viewIndex !== null && (
        <div
          className="w-full flex justify-center mb-10"
          ref={imageContainerRef}
        >
          {images.map((img, i) => (
            <div key={i} className={`${loader || error ? "hidden" : ""}`}>
              <Zoom>
                <img
                  src={img}
                  alt=""
                  onLoad={() => {
                    setLoader(false);
                    visited.current[viewIndex] = true;
                  }}
                  onError={() =>
                    setLoader(false) || setError("Error fetching images")
                  }
                  className={`w-40 mx-1 rounded-lg`}
                />
              </Zoom>
            </div>
          ))}
          {loader && <BarLoader size={50} color="white" className="h-8" />}
          {error && (
            <div className="text-red-500 bg-black bg-opacity-35 px-2 py-1 rounded-lg h-8 flex justify-center">
              {error}
            </div>
          )}
        </div>
      )}
      <div
        className={`${!orders?.length ? "hidden" : ""} w-5/6 rounded-2xl h-[500px] flex items-center justify-center overflow-hidden relative mb-10 border-4 border-green-700`}
      >
        <div className="absolute z-[9999] right-2 top-2 flex flex-col gap-y-2">
          <button
            onClick={(e) =>
              layer === "street" ? setLayer("satellite") : setLayer("street")
            }
            className="border-2 border-white rounded-lg text-white bg-black bg-opacity-60 py-1 px-2 hover:bg-opacity-100"
          >
            Toggle Layer
          </button>
        </div>

        <MapContainer
          center={[0, 0]}
          zoom={3}
          style={{ height: "100vh", width: "100%", cursor: "default" }}
          ref={mapRef}
        >
          {layer === "street" && (
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
              noWrap
            />
          )}

          {layer === "satellite" && (
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
              noWrap
            />
          )}

          {points &&
            points.map((position, idx) => (
              <Marker
                className="z-50 cursor-default"
                key={idx}
                position={position}
                icon={mapIconSecondary}
              />
            ))}
          {points && points.length >= 3 && (
            <Polygon
              positions={points}
              color={"red"}
              fillColor={"rgba(255, 0, 0, 0.2)"}
              fillOpacity={0.5}
            />
          )}
        </MapContainer>
      </div>
    </div>
  );
};

export default OrderHistory;
