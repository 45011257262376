import Magnifier from "resources/analyze/search.svg";
import { useContext, useRef, useState, useEffect } from "react";
import { RingLoader } from "react-spinners";
import GeoContext from "contexts/GeoContext";
import { geocodeAddress } from "apis/positionstack";
import { getZoomLevel } from "utils";
import { getCurrentWeather } from "apis/weather";

const Search = () => {
  const { loader, setLoader, setLocation } = useContext(GeoContext);

  const [searchError, setSearchError] = useState(null);
  const [searches, setSearches] = useState(null);

  const dropdownRef = useRef(null);
  const address = useRef("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearches(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const goToLocation = (search) => {
    const { latitude, longitude, type } = search;

    const zoom = getZoomLevel(type);

    setLocation({ latitude, longitude, zoom });
  };

  const handleSearchSelection = (search) => {
    setSearches(null);
    goToLocation(search);
  };

  const formatSearch = (search) => {
    const name = search.name || "Unknown Place";
    const region = search.region || "";
    const country = search.country || "Unknown Country";

    const summary = region
      ? `${name}, ${region}, ${country}`
      : `${name}, ${country}`;

    return summary;
  };

  const handleSearch = async () => {
    if (!address.current || address.current === "") return;
    setLoader(true);
    setSearchError(null);
    setSearches(null);
    try {
      const results = (await geocodeAddress(address.current)).data;
      if (!results.length) {
        setSearchError("No results found!");
        setLoader(false);
        return;
      }

      const topResults = results.slice(0, 6);
      if (topResults.length === 1) goToLocation(topResults[0]);
      else setSearches(topResults);

      setLoader(false);
    } catch (e) {
      setSearchError("Something went wrong finding this address!");
      setLoader(false);
    }
  };

  return (
    <div className="w-full max-w-[600px] text-white">
      <div className="text-5xl font-bold underline">
        Let’s analyze your land
      </div>
      <div className="opacity-90 mt-6">
        Find quality matrices for better land maintenance
      </div>
      <div className="relative p-4 pl-8 mt-6 w-[90%] border border-white flex rounded-md justify-between">
        <input
          className="w-full bg-transparent disabled:bg-transparent placeholder-white text-white placeholder-opacity-80 border-none focus:outline-none"
          placeholder="Search your land (or select on map)"
          onChange={(e) => (address.current = e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
        />
        {loader ? (
          <RingLoader size={24} color={"cyan"} />
        ) : (
          <img
            src={Magnifier}
            alt=""
            className="cursor-pointer hover:scale-110"
            onClick={handleSearch}
          />
        )}
        {searches && (
          <div
            ref={dropdownRef}
            className="absolute left-0 top-[100%] w-full flex flex-col items-center"
          >
            {searches.map((search) => (
              <div
                key={search.id} // Make sure to use a unique key
                className="cursor-pointer bg-gray-700 hover:bg-gray-900 hover:bg-opacity-30 bg-opacity-30 w-full pl-2 py-[2px]"
                onClick={() => handleSearchSelection(search)}
              >
                {formatSearch(search)}
              </div>
            ))}
          </div>
        )}
      </div>
      {searchError && (
        <div className="text-orange-500 text-sm mt-1 ml-2">{searchError}</div>
      )}
    </div>
  );
};

export default Search;
