import axios from "apis/positionstack/axios";

export const geocodeAddress = async (address) => {
  try {
    const response = await axios.get("/forward", {
      params: {
        query: address,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error geocoding address:", error);
    throw error;
  }
};

export const reverseGeocode = async (latitude, longitude) => {
  try {
    const response = await axios.get("/reverse", {
      params: {
        query: `${latitude},${longitude}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error reverse geocoding coordinates:", error);
    throw error;
  }
};
