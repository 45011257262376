import { useLocation, useNavigate } from "react-router-dom";
import { PAGES } from "constants/index";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="absolute w-full text-white z-50 flex justify-between">
      <div className="mt-12 ml-16 w-4/5 flex justify-between">
        <div className="text-4xl font-semibold">LOGO</div>
        <div className="flex space-x-4">
          {PAGES.map((page) => (
            <div
              className={`text-base ${
                page.path === location.pathname ? "underline font-bold" : ""
              } cursor-pointer`}
              onClick={() => navigate(page.path)}
              key={page.path}
            >
              {page.title.toUpperCase()}
            </div>
          ))}
        </div>
      </div>
      <div
        className="mt-12 mr-4 text-gray-400 font-bold text-base cursor-pointer"
        onClick={() => navigate("/account")}
      >
        Account
      </div>
    </div>
  );
};

export default Header;
