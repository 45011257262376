const CustomInput = ({
  placeholder,
  onChange,
  className,
  type = "text",
  hidden = false,
}) => {
  return (
    <div
      className={`p-3 pl-4 mt-2 border border-white rounded-md ${hidden ? "opacity-0 max-h-0 !py-0 !m-0" : "opacity-100 max-h-40"} transition-all duration-300 overflow-hidden ${className}`}
    >
      <input
        className="w-full bg-transparent disabled:bg-transparent placeholder-white text-white placeholder-opacity-80 border-none focus:outline-none"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        type={type}
      />
    </div>
  );
};

export default CustomInput;
