import { ClockLoader } from "react-spinners";

const CustomButton = ({
  text,
  onClick = () => {},
  disabled = false,
  loading = false,
  className = "",
}) => {
  return (
    <div
      className={`px-4 py-2 mt-4 text-xl font-medium text-white rounded-lg select-none transition-all duration-300 ${disabled ? "bg-gray-600" : "bg-green-600 cursor-pointer bg-opacity-50 hover:bg-opacity-100"} flex justify-center items-center ${className}`}
      onClick={() => !disabled && !loading && onClick()}
    >
      {loading ? <ClockLoader color="white" size={28} /> : text}
    </div>
  );
};

export default CustomButton;
