import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import GeoContext from "contexts/GeoContext";
import scrollIntoView from "scroll-into-view-if-needed";
import { mapIcon, mapMidIcon } from "constants/index";
import { getMidPoints } from "utils";

const Map = () => {
  const { points, setPoints, location } = useContext(GeoContext);

  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);

  const [midPoints, setMidPoints] = useState(getMidPoints(points));

  useEffect(() => setMidPoints(getMidPoints(points)), [points]);

  const [layer, setLayer] = useState("satellite");

  const handleMapClick = useCallback((event) => {
    const { lat, lng } = event.latlng;
    setPoints((prevPoints) => [...prevPoints, [lat, lng]]);
  }, []);

  const handleClick = (index) => {
    let updatedPoints = [...points];
    updatedPoints.splice(index, 1);
    setPoints(updatedPoints);
  };

  const handleDrag = (idx, event) => {
    const { lat, lng } = event.target._latlng;
    const newPoints = [...points];
    newPoints[idx] = [lat, lng];
    setPoints(newPoints);
  };

  const handleMidPointClick = (idx) => {
    const midpoint = [...midPoints[idx]];

    const newPoints = [...points];
    if (points.length > 2 && idx + 1 === midPoints.length)
      newPoints.push(midpoint);
    else newPoints.splice(idx + 1, 0, midpoint);

    setMidPoints(getMidPoints(newPoints));

    setPoints(newPoints);
  };

  const handleUndo = useCallback(() => {
    let updatedPoints = [...points];
    updatedPoints.pop();
    setPoints(updatedPoints);
  }, [points]);

  const handleClear = useCallback(() => {
    setPoints([]);
  }, [setPoints]);

  const MapEvents = () => {
    useMapEvents({ click: handleMapClick });
    return null;
  };

  const flyToLocation = (lat, lon, zoom = null, animate = true) => {
    if (mapRef.current) {
      mapRef.current.flyTo([lat, lon], zoom || 12 || mapRef.current.getZoom(), {
        animate,
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        handleUndo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleUndo]);

  useEffect(() => {
    if (!mapContainerRef.current || !mapRef.current || !location) return;

    scrollIntoView(mapContainerRef.current, {
      behavior: "smooth",
      scrollMode: "always",
      block: "center",
    });

    flyToLocation(location.latitude, location.longitude, location.zoom);
  }, [location]);

  return (
    <div
      className={`w-full flex justify-center ${location ? "opacity-100" : "opacity-0 pointer-events-none"} transition-all duration-[2000ms]`}
    >
      <div
        className="w-5/6 rounded-2xl h-[500px] overflow-hidden relative"
        ref={mapContainerRef}
      >
        <div className="absolute z-[9999] right-2 top-2 flex flex-col gap-y-2">
          <button
            onClick={(e) => {
              layer === "street" ? setLayer("satellite") : setLayer("street");
              console.log(points);
            }}
            className="border-2 border-white rounded-lg text-white bg-black bg-opacity-60 py-1 px-2 hover:bg-opacity-100"
          >
            Toggle Layer
          </button>
          {points.length >= 1 && (
            <button
              onClick={handleClear}
              className="border-2 border-white rounded-lg text-red-400 bg-black bg-opacity-60 py-1 px-2 hover:bg-opacity-100"
            >
              Clear Points
            </button>
          )}
        </div>

        <MapContainer
          center={[0, 0]}
          zoom={3}
          style={{ height: "100vh", width: "100%", cursor: "default" }}
          ref={mapRef}
        >
          {layer === "street" && (
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
              noWrap
            />
          )}

          {layer === "satellite" && (
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
              noWrap
            />
          )}

          {points.map((position, idx) => (
            <Marker
              className="z-50"
              key={idx}
              position={position}
              icon={mapIcon}
              draggable
              eventHandlers={{
                click: () => handleClick(idx),
                drag: (event) => handleDrag(idx, event),
              }}
            />
          ))}

          {midPoints.map((position, idx) => (
            <Marker
              className="z-50"
              key={idx}
              position={position}
              icon={mapMidIcon}
              eventHandlers={{
                click: () => handleMidPointClick(idx),
                // drag: (e) => handleMidPointDrag(e, idx),
                // dragend: () => handleMidPointDragEnd(idx),
              }}
            />
          ))}
          {points.length >= 2 && (
            <Polygon
              positions={points}
              color={"blue"}
              fillColor={"rgba(0, 0, 255, 0.2)"}
              fillOpacity={0.5}
            />
          )}
          <MapEvents />
        </MapContainer>
      </div>
    </div>
  );
};

export default Map;
