import Divider from "components/common/Divider";
import Field from "resources/home/field4.png";
import Tick from "resources/home/icons/Tick.jsx";

const TickItem = ({ children }) => {
  return (
    <div className="flex mb-3 items-start">
      <Tick className="w-3 mr-2 mt-[5px] text-[#C1DB62]" />
      <div className="text-sm font-medium text-[#000B33]">{children}</div>
    </div>
  );
};

const Distinctions = () => {
  return (
    <div>
      <div className="font-medium text-[40px] text-center mt-20 text-[#262B35]">
        Here's what sets us apart
      </div>
      <Divider />
      <div className="mt-20 flex w-full">
        <div className="w-1/2 flex flex-col justify-center">
          <div className="mx-20">
            <div className="font-medium text-[42px] leading-tight">
              The Data Advantage:
            </div>
            <div className="font-medium text-3xl mt-1">
              Why We're Your Trusted Partner
            </div>
            <div className="opacity-70 text-sm text-[#000B33] mt-8 w-9/12">
              We leverage cutting-edge satellite technology and advanced
              analytics to deliver unparalleled data accuracy.
            </div>
            <div className="grid grid-cols-2 justify-between mt-6 max-w-[600px]">
              <TickItem>Seamless Integrations</TickItem>
              <TickItem>Unparalleled Data Accuracy</TickItem>
              <TickItem>Actionable Reporting</TickItem>
              <TickItem>User-Friendly Platform</TickItem>
              <TickItem>Expert Support</TickItem>
              <TickItem>Scalability</TickItem>
            </div>
          </div>
        </div>
        <img src={Field} alt="" className="w-1/2 rounded-tl-[50%]"></img>
      </div>
    </div>
  );
};

export default Distinctions;
