import Visa from "resources/common/icons/visa.svg";
import Mastercard from "resources/common/icons/mastercard.svg";
import Bitcoin from "resources/common/icons/bitcoin.svg";
import Facebook from "resources/common/icons/facebook.svg";
import Instagram from "resources/common/icons/instagram.svg";
import Linkedin from "resources/common/icons/linkedin.svg";
import Twitter from "resources/common/icons/twitter.svg";
import Youtube from "resources/common/icons/youtube.svg";

const Footer = () => {
  return (
    <div className="bg-[#3C5E23] w-full p-16 pl-32 text-white">
      <div className="flex flex-wrap justify-between">
        <div className="text-4xl font-semibold mt-2">LOGO</div>
        <div>
          <div className="text-xl font-medium mb-8">Quick Link</div>
          <div className="text-[#E0E0E0] mb-3 cursor-pointer">Home</div>
          <div className="text-[#E0E0E0] mb-3 cursor-pointer">About us</div>
          <div className="text-[#E0E0E0] mb-3 cursor-pointer">Gallery</div>
          <div className="text-[#E0E0E0] mb-3 cursor-pointer">Contact</div>
        </div>
        <div>
          <div className="text-xl font-medium mb-8">Resources</div>
          <div className="text-[#E0E0E0] mb-3 cursor-pointer">
            Measurement Guide
          </div>
          <div className="text-[#E0E0E0] mb-3 cursor-pointer">
            Glossary of Terms
          </div>
          <div className="text-[#E0E0E0] mb-3 cursor-pointer">Case Studies</div>
        </div>
        <div>
          <div className="font-medium text-[32px] max-w-[350px] mb-8">
            We accept following payment systems
          </div>
          <div className="flex">
            <div className="group w-24 h-16 rounded-lg flex justify-center items-center bg-[#44642c] mr-4">
              <img
                src={Visa}
                alt=""
                className="transition-all duration-500 group-hover:skew-x-12"
              />
            </div>
            <div className="group w-24 h-16 rounded-lg flex justify-center items-center bg-[#44642c] mr-4">
              <img
                src={Mastercard}
                alt=""
                className="transition-all duration-500 group-hover:skew-x-12"
              />
            </div>
            <div className="group w-24 h-16 rounded-lg flex justify-center items-center bg-[#44642c] mr-4">
              <img
                src={Bitcoin}
                alt=""
                className="transition-all duration-500 group-hover:skew-x-12"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-between mt-16">
        <div className=" text-sm">©2024 All rights reserved</div>
        <div className="flex">
          <img src={Facebook} alt="" className="mr-8 cursor-pointer" />
          <img src={Instagram} alt="" className="mr-8 cursor-pointer" />
          <img src={Youtube} alt="" className="mr-8 cursor-pointer" />
          <img src={Twitter} alt="" className="mr-8 cursor-pointer" />
          <img src={Linkedin} alt="" className="mr-8 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
