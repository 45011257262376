import Overview from "components/common/Overview";
import Feedback from "components/contact/Feedback";

const Contact = () => {
  return (
    <div>
      <Overview
        title={"Contact Us"}
        desc={
          "Help millions of land owners through sharing your feedback and making this platform even more useful!"
        }
        buttonType="path"
        pageName="Contact Us"
        rightSection="contact"
      />
      <Feedback />
    </div>
  );
};

export default Contact;
