const RightArrow = ({ className = "" }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.57651 1.2352L6.2027 0.609014C6.46785 0.34387 6.89659 0.34387 7.15891 0.609014L12.6423 6.08959C12.9074 6.35473 12.9074 6.78347 12.6423 7.0458L7.15891 12.5292C6.89377 12.7943 6.46503 12.7943 6.2027 12.5292L5.57651 11.903C5.30855 11.635 5.31419 11.1978 5.58779 10.9355L8.98671 7.69737H0.880087C0.504937 7.69737 0.203125 7.39556 0.203125 7.02041V6.11779C0.203125 5.74264 0.504937 5.44083 0.880087 5.44083H8.98671L5.58779 2.2027C5.31137 1.94037 5.30573 1.50317 5.57651 1.2352Z"
      fill="currentColor"
    />
  </svg>
);

export default RightArrow;
