import Divider from "components/common/Divider";
import Golf from "resources/about/golf.png";

const Commitment = () => {
  return (
    <div className="my-20 flex justify-between">
      <img className="w-[550px] h-[480px] rounded-tr-[50%]" src={Golf} alt="" />
      <div className="w-full flex justify-center max-w-[1000px]">
        <div className="max-w-[500px]">
          <div className="text-[40px] font-medium text-[#262B35] mt-10">
            Our Commitment to You
          </div>
          <Divider className="w-full mt-0" />
          <div className="text-sm text-[#000B33] opacity-70 mt-4 pr-10">
            We are committed to building long-term partnerships with our
            customers. Our dedicated support team is available to assist you in
            interpreting data, maximizing the value of our platform, and
            achieving your land management goals. We believe in continuous
            improvement and are constantly exploring new technologies and data
            sources to enhance our services.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commitment;
