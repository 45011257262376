import Divider from "components/common/Divider";

const ContactBox = () => {
  return (
    <div className="w-full flex justify-center my-10 rounded-xl">
      <div className="w-full max-w-[750px] shadow-card p-4 flex flex-col items-center">
        <input
          type="text"
          className="bg-[#EDF8C4] bg-opacity-25 text-[#000B33] placeholder-[#000B33] placeholder-opacity-70 rounded p-4 my-4 w-full"
          placeholder="Enter your name*"
        />
        <input
          type="text"
          className="bg-[#EDF8C4] bg-opacity-25 text-[#000B33] placeholder-[#000B33] placeholder-opacity-70 rounded p-4 mb-4 w-full"
          placeholder="Enter your email address"
        />
        <textarea
          type="text"
          className="bg-[#EDF8C4] bg-opacity-25 text-[#000B33] placeholder-[#000B33] placeholder-opacity-70 rounded p-4 mb-8 w-full h-32"
          placeholder="Write your message"
        />
        <div className="w-48 rounded-md px-4 py-3 mb-5 bg-[#3C5E23] hover:bg-[#507e2f] transition-colors duration-100 flex justify-center items-center font-medium text-white cursor-pointer">
          Send Message
        </div>
      </div>
    </div>
  );
};
const Feedback = () => {
  return (
    <div className="my-28">
      <div className="text-center font-medium text-[42px] text-[#262B35]">
        Have Feedback or Suggestions?
      </div>
      <Divider className="w-1/2" />
      <div className="flex justify-center">
        <div className="text-center text-[#7E7E7E] mt-6 max-w-[450px]">
          Your feedback helps us improve our services
        </div>
      </div>
      <ContactBox />
    </div>
  );
};

export default Feedback;
