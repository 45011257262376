import Divider from "components/common/Divider";
import Luffy from "resources/about/luffy.png";

const Mission = () => {
  return (
    <div className="p-20 flex justify-between items-center">
      <div className="w-96">
        <div className="text-[42px] font-medium">Our Mission</div>
        <Divider className=" w-[260px] mx-0 mt-0 border-[#3C5E23]" />
        <div className=" text-sm text-[#0D253C] opacity-70 mt-6">
          Our mission is to revolutionize land management by providing
          easy-to-understand, satellite-based data. We believe that by arming
          you with the right information, you can optimize your land for maximum
          yield, sustainability, and profitability.
        </div>
      </div>
      <img className="w-[550px] h-[400px]" src={Luffy} alt="" />
    </div>
  );
};

export default Mission;
