import PackageCard from "components/packages/PackageCard";
import Tree from "resources/packages/icons/tree.svg";
import Leaves from "resources/packages/icons/leaves.svg";
import Plant from "resources/packages/icons/plant.svg";
import { package1Benefits } from "constants";
import { package1NonBenefits } from "constants";
import { package2Benefits } from "constants";
import { package3Benefits } from "constants";

const PackageCollection = () => {
  return (
    <div className="flex flex-wrap justify-center mb-10 mt-4">
      <PackageCard
        image={Tree}
        title="Free Plan"
        desc="Offers essential features for landowners to get started with satellite-based land assessment."
        price={"$0.00"}
        benefits={package1Benefits}
        nonBenefits={package1NonBenefits}
      />
      <PackageCard
        image={Leaves}
        title="Elite Plan"
        desc="Offers more advanced features for landowners who need detailed insights and more frequent updates. "
        price={"$19.00"}
        benefits={package2Benefits}
        yearlyPrice={"$199.99"}
        isRecommended
      />
      <PackageCard
        image={Plant}
        title="Premium Plan"
        desc="Offers essential features for landowners to get started with satellite-based land assessment."
        price={"$49.00"}
        benefits={package3Benefits}
        yearlyPrice={"$499.99"}
      />
    </div>
  );
};

export default PackageCollection;
