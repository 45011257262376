import Trend from "resources/home/icons/trend.svg";
import Cost from "resources/home/icons/cost.svg";
import Boxes from "resources/home/icons/boxes.svg";
import Field from "resources/home/field3.png";

const Benefit = ({ index, icon, title, text }) => {
  return (
    <div className="flex group">
      <div className="relative select-none">
        <div className="font-inter font-bold text-[95px] text-[#25314C] opacity-20">
          {index}
        </div>
        <div className="relative">
          <div className="absolute z-10 -top-20 ml-6 w-[60px] h-[60px] flex justify-center items-center rounded-full overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-r from-[#C1DB62] to-[#EDF8C4] transition-opacity duration-1000 group-hover:opacity-0"></div>
            <div className="absolute inset-0 bg-gradient-to-r from-[#2cc748] to-[#b0f6c7] opacity-0 group-hover:opacity-100 transition-opacity duration-1000"></div>
            <img src={icon} alt="" className="relative w-8 h-8 z-10" />
          </div>
        </div>
      </div>
      <div className="mt-20 ml-16 text-[#000B33] max-w-[60%]">
        <div className="text-xl font-medium mb-2">{title}</div>
        <div className="text-sm">{text}</div>
      </div>
    </div>
  );
};

const Benefits = () => {
  return (
    <div className="flex">
      <div className="min-w-[45vw]">
        <div className="ml-20 mt-10">
          <div className=" font-bold text-[#BABABA] text-base tracking-[8px]">
            GET THESE
          </div>
          <div className="mt-2 text-[42px] font-medium text-[#262B35]">
            Benefits
          </div>
          <Benefit
            index={1}
            icon={Trend}
            title={"Increased Crop Yields"}
            text={
              "Gain valuable insights to optimize growing conditions, leading to healthier crops and improved yields."
            }
          />
          <Benefit
            index={2}
            icon={Cost}
            title={"Reduced Costs"}
            text={
              "Optimize resource use by identifying areas requiring less fertilizer or water, leading to cost savings."
            }
          />
          <Benefit
            index={3}
            icon={Boxes}
            title={"Improved Sustainability"}
            text={
              "Promote sustainable land management practices by making data-driven decisions for your land."
            }
          />
        </div>
      </div>
      <img
        src={Field}
        alt=""
        className="w-full h-[750px] rounded-tl-[50%] shadow-vertical"
      />
    </div>
  );
};

export default Benefits;
