const LeftArrow = ({ className = "" }) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.8916 11.903L7.26541 12.5292C7.00027 12.7943 6.57153 12.7943 6.3092 12.5292L0.825811 7.04862C0.560667 6.78347 0.560667 6.35473 0.825811 6.09241L6.3092 0.609014C6.57435 0.34387 7.00309 0.34387 7.26541 0.609014L7.8916 1.2352C8.15957 1.50317 8.15393 1.94037 7.88032 2.2027L4.48141 5.44083H12.588C12.9632 5.44083 13.265 5.74264 13.265 6.11779V7.02041C13.265 7.39556 12.9632 7.69737 12.588 7.69737H4.48141L7.88032 10.9355C8.15675 11.1978 8.16239 11.635 7.8916 11.903Z"
      fill="currentColor"
    />
  </svg>
);

export default LeftArrow;
