const Card = ({ amount, text, variant }) => {
  return (
    <div
      className={`w-[250px] rounded-lg mx-2 px-4 py-2 flex items-center justify-center text-[#3C5E23] ${
        variant
          ? "bg-gradient-to-r from-[#EDF8C4] to-[#C1DB62]"
          : "bg-gradient-to-r from-[#C1DB62] to-[#EDF8C4]"
      }`}
    >
      <div className="font-medium text-[54px] mr-2">{amount}</div>
      <div className="text-xl leading-tight">{text}</div>
    </div>
  );
};

const Analytics = () => {
  return (
    <div className="flex justify-center my-20">
      <Card amount={"28K"} text={"Managers Empowered"} variant={1} />
      <Card amount={"13K"} text={"Hectares Analyzed"} variant={0} />
      <Card amount={"68K"} text={"Farmers Insured"} variant={1} />
      <Card amount={"10K"} text={"Reports Delivered"} variant={0} />
    </div>
  );
};

export default Analytics;
