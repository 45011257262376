const Tick = ({ className }) => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.1595 0.586531C13.0758 0.502175 12.9763 0.43522 12.8666 0.389529C12.757 0.343837 12.6393 0.320312 12.5205 0.320312C12.4017 0.320312 12.2841 0.343837 12.1744 0.389529C12.0647 0.43522 11.9652 0.502175 11.8815 0.586531L5.17651 7.30053L2.35951 4.47453C2.27264 4.39062 2.17009 4.32463 2.05773 4.28035C1.94536 4.23607 1.82536 4.21435 1.7046 4.21644C1.58384 4.21853 1.46467 4.24438 1.3539 4.29253C1.24313 4.34067 1.14293 4.41016 1.05901 4.49703C0.975097 4.5839 0.909114 4.68645 0.864831 4.79882C0.820547 4.91119 0.79883 5.03118 0.800919 5.15194C0.803008 5.2727 0.828863 5.39187 0.877007 5.50264C0.925151 5.61341 0.994642 5.71362 1.08151 5.79753L4.53751 9.25353C4.62118 9.33789 4.72072 9.40484 4.83039 9.45053C4.94007 9.49622 5.0577 9.51975 5.17651 9.51975C5.29532 9.51975 5.41296 9.49622 5.52263 9.45053C5.6323 9.40484 5.73185 9.33789 5.81551 9.25353L13.1595 1.90953C13.2509 1.82525 13.3238 1.72297 13.3736 1.60912C13.4235 1.49527 13.4493 1.37232 13.4493 1.24803C13.4493 1.12374 13.4235 1.00079 13.3736 0.886945C13.3238 0.773096 13.2509 0.670809 13.1595 0.586531Z"
      fill={"currentColor"}
    />
  </svg>
);

export default Tick;
