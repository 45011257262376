import { requestImages } from "apis/backend";
import GeoContext from "contexts/GeoContext";
import React, { useContext, useState } from "react";
import { ClockLoader } from "react-spinners";
import ArrowLeft from "resources/analyze/arrow-left.svg";
import Zoom from "react-medium-image-zoom";
import { getAverageCoord } from "utils";
import { getCurrentWeather } from "apis/weather";
import WeatherBar from "components/common/WeatherBar";
import WeatherCard from "components/common/WeatherCard";

const Parameter = ({ text, selectionColor, onSelect, isChecked }) => {
  return (
    <div
      className={`flex justify-start cursor-pointer items-center py-3 px-6 rounded-lg select-none mx-2 my-1`}
      style={{
        backgroundColor: isChecked
          ? selectionColor
          : "rgba(255, 255, 255, 0.4)", // Using rgba for white with opacity
      }}
      onClick={onSelect}
    >
      <div
        className={`w-5 h-5 flex items-center justify-center rounded-full border-2 ${isChecked ? "bg-white" : "bg-transparent border-white"}`}
      >
        {isChecked && (
          <svg
            className="w-4 h-4 text-[#3C5E23]"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
      </div>
      <div className="font-medium opacity-70 ml-2">{text}</div>
    </div>
  );
};

const parameters = [
  "Vegetation Health",
  "Soil Moisture",
  "Nitrogen Content",
  "Surface Water",
  "Burned Land",
];

const parameterColors = [
  "#4CAF50", // Vegetation Health (Green)
  "#3F51B5", // Soil Moisture (Blue)
  "#FFC107", // Nitrogen Content (Orange-Yellow)
  "#2196F3", // Surface Water (Light Blue)
  "#F44336", // Burned Land (Red)
];

const Params = () => {
  const { params, setParams, points, totalArea } = useContext(GeoContext);

  const [loader, setLoader] = useState(false);

  const [images, setImages] = useState([]);

  const [weather, setWeather] = useState(null);

  const handleSelect = (index) => {
    setParams((prevParams) =>
      prevParams.map((param, i) => (i === index ? !param : param))
    );
  };

  const handleSubmit = async () => {
    if (loader) return;
    setLoader(true);
    try {
      const { lat, lon } = getAverageCoord(points);
      const [data, weather] = await Promise.all([
        requestImages(points),
        getCurrentWeather(lat, lon),
      ]);

      setLoader(false);
      setWeather(weather);
      setImages(data.search_result);
    } catch (e) {
      setLoader(false);
    }
  };
  const disableSubmit =
    points.length < 3 || !params.some((entry) => entry) || totalArea > 500000;

  return (
    <div>
      <div
        className={`${points.length >= 3 ? "opacity-100 max-h-[1000px]" : "opacity-0 max-h-0"} transition-all duration-1000 text-white flex flex-col items-center pb-10`}
      >
        <div className="mt-6 text-[32px] font-bold">Select Parameters</div>
        <div className="opacity-90 mt-4">
          Which parameters do you want to find about your land?
        </div>
        <div className="mt-4 grid grid-cols-2 w-fit">
          {parameters.map((p, index) => (
            <Parameter
              text={parameters[index]}
              selectionColor={parameterColors[index]}
              isChecked={params[index]}
              onSelect={() => handleSelect(index)}
              key={index}
            />
          ))}
        </div>
        <div
          className={`mt-4 w-full h-14 flex justify-center items-center rounded-lg bg-gradient-to-r ${disableSubmit || loader ? "from-[#a1a1a1] to-[#717171]" : "from-[#EDF8C4] to-[#C1DB62] cursor-pointer"} max-w-[300px]`}
          onClick={handleSubmit}
        >
          <div className="font-medium text-[#3C5E23] mr-1">View Results</div>
          {loader ? (
            <ClockLoader size={20} className="ml-2" color={"#3C5E23"} />
          ) : (
            <img src={ArrowLeft} alt="" />
          )}
        </div>
      </div>
      {images?.length ? (
        <div className="text-center text-white text-3xl font-medium mt-4">
          Result Images
        </div>
      ) : null}
      <div className="py-10 flex flex-wrap justify-center items-center">
        {images.map((img, index) => (
          <div className="p-2" key={index}>
            <Zoom>
              <img
                src={process.env.REACT_APP_BASE_URL + img}
                alt=""
                className="w-40 h-40"
              />
            </Zoom>
          </div>
        ))}
      </div>
      {weather && (
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-col w-fit">
            <div className="text-center text-white text-3xl font-bold mt-4">
              Weather Information
            </div>
            <div className="my-2 border-b border-white" />
          </div>
        </div>
      )}
      {weather && (
        <div className="flex justify-center pb-4">
          <WeatherBar weather={weather} />
        </div>
      )}
    </div>
  );
};

export default Params;
