import axios from "axios";

export const WEATHER_API_KEY = "92bb1b1f2782427a915162940242308";

const instance = axios.create({
  baseURL: "http://api.positionstack.com/v1",
  params: {
    key: WEATHER_API_KEY,
  },
});

export default instance;
