import DewPoint from "resources/common/icons/weather/Dew Point.png";
import Humidity from "resources/common/icons/weather/Humidity.png";
import Precipitation from "resources/common/icons/weather/Precipitation.png";
import Temperature from "resources/common/icons/weather/Temperature.png";
import Wind from "resources/common/icons/weather/Wind.png";

const WeatherItem = ({ icon, text, value }) => {
  return (
    <div className="flex h-20 group text-white hover:scale-110 transition-scale duration-300">
      <img src={icon} className="w-20 h-20 mr-4" alt="icon" />
      <div className="flex flex-col justify-center">
        <div className="font-bold text-2xl">{value}</div>
        <div className=" font-medium max-h-0 group-hover:max-h-20 transition-all duration-500 overflow-hidden">
          {text}
        </div>
      </div>
    </div>
  );
};

const Seperator = () => {
  return <div className="border-l border-white mx-10" />;
};

const WeatherBar = ({ weather }) => {
  return (
    <div className="flex flex-col items-center my-4 pb-3 p-4">
      <div className="flex flex-col items-center mb-4">
        <div className="opacity-90 text-xl text-white font-light leading-none mb-2">
          {weather.location.name +
            ", " +
            weather.location.region +
            ", " +
            weather.location.country}
        </div>
        <div className="flex items-center">
          <img
            className="w-12 h-12 object-contain"
            src={weather.current.condition.icon}
            alt=""
          />
          <div className="text-white opacity-90">
            {weather.current.condition.text}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-4 mb-10">
        <WeatherItem
          icon={Temperature}
          text="Temperature"
          value={`${weather.current.temp_f} °F (${weather.current.temp_c} °C)`}
        />
        <Seperator />
        <WeatherItem
          icon={Wind}
          text="Wind"
          value={`${weather.current.wind_mph} mph (${weather.current.wind_dir})`}
        />
        <Seperator />
        <WeatherItem
          icon={Precipitation}
          text="Precipitation"
          value={`${weather.current.precip_mm} mm`}
        />
      </div>
      <div className="w-full flex justify-center mt-4">
        <WeatherItem
          icon={Humidity}
          text="Humidity"
          value={`${weather.current.humidity}%`}
        />
        <Seperator />
        <WeatherItem
          icon={DewPoint}
          text="Dew point"
          value={`${weather.current.dewpoint_f} °F (${weather.current.dewpoint_c} °C)`}
        />
      </div>
    </div>
  );
};

export default WeatherBar;
