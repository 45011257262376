import { resetPassword, verifyResetpassword } from "apis/backend";
import Background from "components/common/Background";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { findMessage } from "utils";
import Input from "components/common/CustomInput";
import CustomButton from "components/common/CustomButton";

const VerifyResetPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  const [data, setData] = useState({ pass: "", rePass: "" });
  const [loader, setLoader] = useState(false);
  const [resetToken, setResetToken] = useState(null);

  const handleResetPassword = async () => {
    if (loader) return;
    const { pass, rePass } = data;
    if (!pass.length || !rePass.length)
      return setError("All the fields are required!");
    if (pass !== rePass) return setError("Passwords do not match!");
    if (pass.length < 6)
      return setError("Password must be at least 6 digits long!");

    try {
      setError(null);
      setLoader(true);
      await resetPassword(email, pass, resetToken);
      toast.success("Password reset successful! Redirecting...");
      setTimeout(() => navigate("/account"), 3000);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      toast.error(findMessage(e, "Error resetting password!"));
    }
  };

  const [error, setError] = useState(null);

  useEffect(() => {
    const handleVerifyResetPassword = async () => {
      try {
        const data = await verifyResetpassword(email, token);
        setResetToken(data.token);
      } catch (e) {
        setError(findMessage(e, "Unexpected error during OTP verification"));
      }
    };

    if (!email || !token) return;
    handleVerifyResetPassword();
  }, [token]);

  return (
    <Background>
      {!resetToken && !email && !token && (
        <div className="text-center text-3xl font-medium py-40 text-white">
          Invalid URL
        </div>
      )}
      {!resetToken && error && (
        <div className="text-center text-3xl text-red-400 font-medium py-40">
          {error}
        </div>
      )}
      {!resetToken && !error && (
        <div className="text-center text-3xl font-medium py-40 text-white">
          Verifying token...
        </div>
      )}

      {resetToken && (
        <div className="flex flex-col items-center text-white mt-10 pb-40 pt-2">
          <div className="flex flex-col items-center bg-black bg-opacity-25 backdrop-blur-md rounded-2xl px-8 pb-4">
            <div className="mt-4 mb-2 text-2xl text-gray-200 font-medium">
              Reset Password
            </div>
            <div className="flex flex-col">
              <Input
                type={"password"}
                className="w-[300px]"
                placeholder={"Password"}
                onChange={(text) => setData({ ...data, pass: text })}
              />
              <Input
                type={"password"}
                className="w-[300px]"
                placeholder={"Password"}
                onChange={(text) => setData({ ...data, rePass: text })}
              />
              <CustomButton
                text={"Reset"}
                loading={loader}
                onClick={handleResetPassword}
              />
              <div className="text-red-500 text-center text-sm mt-1">
                {error}
              </div>
            </div>
          </div>
        </div>
      )}
    </Background>
  );
};

export default VerifyResetPassword;
