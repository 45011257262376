import { useState } from "react";
import Feature1 from "resources/home/icons/feature1.svg";
import Feature2 from "resources/home/icons/feature2.svg";
import Feature3 from "resources/home/icons/feature3.svg";
import Feature4 from "resources/home/icons/feature4.svg";
import Feature5 from "resources/home/icons/feature5.svg";

import BurnedLand from "resources/home/plots/Burned Land.png";
import CropGrowth from "resources/home/plots/Crop Growth.png";
import Nitrogen from "resources/home/plots/Nitrogen.png";
import SoilMoisture from "resources/home/plots/Soil Moisture.png";
import SurfaceWater from "resources/home/plots/Surface Water.png";

import LeftImage1 from "resources/home/comparison/image0.jpeg";
import RightImage1 from "resources/home/comparison/image1.jpeg";
import LeftImage2 from "resources/home/comparison/image2.jpeg";
import RightImage2 from "resources/home/comparison/image3.jpeg";
import LeftImage3 from "resources/home/comparison/image4.jpeg";
import RightImage3 from "resources/home/comparison/image5.jpeg";
import LeftImage4 from "resources/home/comparison/image6.jpeg";
import RightImage4 from "resources/home/comparison/image7.jpeg";
import LeftImage5 from "resources/home/comparison/image8.jpeg";
import RightImage5 from "resources/home/comparison/image9.jpeg";

import ReactCompareImage from "react-compare-image";

const features = [
  {
    icon: Feature1,
    title: "Vegetation Health",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    plot: CropGrowth,
    leftImage: LeftImage1,
    rightImage: RightImage1,
  },
  {
    icon: Feature2,
    title: "Soil Moisture",
    desc: "Lorem Ipsum",
    plot: SoilMoisture,
    leftImage: LeftImage2,
    rightImage: RightImage2,
  },
  {
    icon: Feature3,
    title: "Nitrogen Enrichment",
    desc: "Lorem Ipsum",
    plot: Nitrogen,
    leftImage: LeftImage3,
    rightImage: RightImage3,
  },
  {
    icon: Feature4,
    title: "Surface Water",
    desc: "Lorem Ipsum",
    plot: SurfaceWater,
    leftImage: LeftImage4,
    rightImage: RightImage4,
  },
  {
    icon: Feature5,
    title: "Burned Land",
    desc: "Lorem Ipsum",
    plot: BurnedLand,
    leftImage: LeftImage5,
    rightImage: RightImage5,
  },
];

const Feature = ({ feature, isSelected, onClick }) => {
  return (
    <div
      className="flex justify-center items-center rounded-full w-44 h-44 mx-3 bg-gradient-to-r from-[#C1DB62] to-[#EDF8C4] cursor-pointer select-none hover:scale-105 transition-all duration-300"
      onClick={onClick}
    >
      <div
        className={`flex flex-col justify-center items-center w-40 h-40 rounded-full bg-white border-8 border-[#3C5E23] ${isSelected ? " border-opacity-100" : "border-opacity-0"} transition-all duration-300`}
      >
        <img
          src={feature.icon}
          alt=""
          className={`${isSelected ? "w-24 h-24" : "w-[60px] h-[60px]"} transition-all duration-300`}
        />
        <div
          className={`transition-all duration-300 overflow-y-hidden ${isSelected ? "max-h-0 opacity-0" : "max-h-[100px] opacity-100"} text-[#000B33] font-semibold w-4/5 text-center mt-2`}
        >
          {feature.title}
        </div>
      </div>
    </div>
  );
};

const FeatureDisplay = ({ feature, isSelected }) => {
  return (
    <div
      className={`absolute w-full ${isSelected ? "opacity-100" : "opacity-0 pointer-events-none"} transition-opacity duration-300`}
    >
      <div className="flex justify-center">
        <div className="w-[800px] mr-20 border-4 border-black rounded-[20px] overflow-hidden">
          <ReactCompareImage
            leftImage={feature.leftImage}
            rightImage={feature.rightImage}
            aspectRatio={"wider"}
            leftImageCss={{
              borderTopLeftRadius: "15px",
              borderBottomLeftRadius: "15px",
            }}
            rightImageCss={{
              borderTopRightRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          />
        </div>
        <div className="flex flex-col justify-center w-72">
          <div className="text-2xl font-medium text-[#262B35] mb-3">
            {feature.title}
          </div>
          <div className="text-[#000B33] text-sm">{feature.desc}</div>
        </div>
      </div>
    </div>
  );
};

const Features = () => {
  const [selectedFeature, selectFeature] = useState(0);

  return (
    <div>
      <div className="flex justify-center">
        {features.map((feature, index) => (
          <Feature
            key={index}
            feature={feature}
            isSelected={selectedFeature === index}
            onClick={() => selectFeature(index)}
          />
        ))}
      </div>
      <div className="relative mt-20 mb-[600px]">
        {features.map((feature, index) => (
          <FeatureDisplay
            key={index}
            feature={feature}
            isSelected={selectedFeature === index}
          />
        ))}
      </div>
    </div>
  );
};

export default Features;
