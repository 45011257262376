import { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { isUserLoggedIn } from "utils";

const LoginAuthenticator = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    if (location.pathname === "/account" || isLoggedIn) return;
    navigate("/account");
    toast.error("Please Login to view the website!");
  }, [location]);
};

export default LoginAuthenticator;
