import BackgroundImage from "resources/analyze/background.png";

const Background = ({ children }) => {
  return (
    <div
      className="w-full h-fit min-h-40 pt-20"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      {children}
    </div>
  );
};

export default Background;
