const Divider = ({ className = "" }) => {
  return (
    <div
      className={
        "w-[38vw] border-[1px] border-[#3C5E23] my-2 mx-auto " + className
      }
    />
  );
};

export default Divider;
