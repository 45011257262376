import AreaCheck from "components/analyze/AreaCheck";
import Earth from "components/analyze/Earth";
import Map from "components/analyze/Map";
import Params from "components/analyze/Params";
import Search from "components/analyze/Search";
import Background from "components/common/Background";
import GeoContext from "contexts/GeoContext";
import { useMemo, useState } from "react";
import { calculatePolygonArea, getGoogleImageryUrl } from "utils";

const Flex = ({ children }) => {
  return (
    <div className="flex justify-start items-center mb-10">{children}</div>
  );
};

const Analyze = () => {
  const [params, setParams] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [points, setPoints] = useState([]);

  const [location, setLocation] = useState(null);

  const [loader, setLoader] = useState(false);

  const totalArea = useMemo(() => calculatePolygonArea(points), [points]);

  const props = {
    params,
    setParams,
    loader,
    setLoader,
    points,
    setPoints,
    location,
    setLocation,
    totalArea,
  };

  return (
    <GeoContext.Provider value={props}>
      <Background>
        <Flex>
          <Earth />
          <Search />
        </Flex>
        <Map />
        <AreaCheck />
        <Params />
      </Background>
    </GeoContext.Provider>
  );
};

export default Analyze;
