import Tick from "resources/home/icons/Tick";
import Button from "resources/common/button.svg";
import ButtonHighlighted from "resources/common/buttonHighlighted.svg";

const ListItem = ({ text, isFaded = false }) => (
  <div className="flex mb-3 items-center self-start">
    <Tick
      className={`${isFaded ? "text-[#ECECEC]" : "text-[#3C5E23]"} mr-2 min-w-4 self-start mt-[6px]`}
    />
    <div
      className={`${isFaded ? "text-[#CBCBCB]" : "text-[#000B33]"} text-[15px]`}
    >
      {text}
    </div>
  </div>
);

const PackageCard = ({
  image,
  title,
  desc,
  price,
  yearlyPrice = null,
  benefits,
  nonBenefits = [],
  isRecommended = false,
  onClick,
}) => {
  const borderStyle = isRecommended ? "border-[3px] border-transparent" : "";

  return (
    <div
      className={`w-96 mx-4 flex flex-col items-center p-10 text-[#000B33] ${borderStyle} h-fit`}
      style={{
        borderImage: isRecommended
          ? "linear-gradient(to right, #869337, #193B2A) 1"
          : undefined,
      }}
    >
      <img src={image} alt="" className="w-12 mb-4" />
      <div className="font-semibold text-[22px] mb-2">{title}</div>
      <div className="font-medium text-center">{desc}</div>
      <div className="text-[67px] font-semibold leading-none mb-4 mt-6">
        {price}
      </div>
      <div className="text-[15px] opacity-80 mb-4">Per Month</div>
      {yearlyPrice && (
        <div className="opacity-80 mb-4">
          or <span className="font-semibold">{yearlyPrice}/year</span>
        </div>
      )}
      {benefits.map((benefit, index) => (
        <ListItem text={benefit} key={index} />
      ))}
      {nonBenefits.map((nonBenefit, index) => (
        <ListItem text={nonBenefit} key={index} isFaded />
      ))}
      {
        <img
          src={isRecommended ? ButtonHighlighted : Button}
          alt="Get Started"
          className="w-64 cursor-pointer mt-8 select-none hover:opacity-9 0"
        />
      }
    </div>
  );
};

export default PackageCard;
